import { computed } from '@vue/composition-api';
import { NotaryProductDocumentType } from '@ligo/dashboard/store';
import { getDocumentsBy } from '../documents.hooks';
import { useStore } from '../../../composables';

export function useFinalizedProduct() {
  const store = useStore();
  const notaryProductId = store.getters['notaryProduct/getNotaryProductId'];

  const { items, loading } = getDocumentsBy(notaryProductId, {
    document_types: [
      NotaryProductDocumentType.FINAL_DEED,
      NotaryProductDocumentType.KVK,
      NotaryProductDocumentType.SHAREHOLDER_REGISTER
    ]
  });

  const kvks = computed(() => {
    const docs = items.value?.filter(
      (doc) => doc.document_type == NotaryProductDocumentType.KVK
    );
    return docs;
  });

  const deeds = computed(() => {
    const docs = items.value?.filter(
      (doc) => doc.document_type == NotaryProductDocumentType.FINAL_DEED
    );

    return docs;
  });

  const shareholder_registers = computed(() => {
    const docs = items.value?.filter(
      (doc) =>
        doc.document_type == NotaryProductDocumentType.SHAREHOLDER_REGISTER
    );

    return docs;
  });

  return { kvks, deeds, shareholder_registers, loading, items };
}
