var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.formDesign.appClass},[_vm._t("form-header",[_c('div',{class:_vm.formDesign.titleClass},[_vm._t("form-title",[(_vm.te('title'))?_c('span',[_vm._v(" "+_vm._s(_vm.t('title'))+" ")]):_vm._e()]),_c('q-space'),(!!_vm.config.useLayout)?_c('div',{staticClass:"col-auto row font-12 justify-end"},_vm._l((_vm.stepperSize),function(i){return _c('q-avatar',{key:i,class:{ 'q-ml-sm': true, 'cursor-pointer': i - 1 < _vm.step },attrs:{"size":"md","color":i - 1 == _vm.step
              ? 'dark-blue'
              : i - 1 < _vm.step
              ? 'np-primary-1100'
              : 'grey-1',"text-color":"white"},on:{"click":function($event){return _vm.onStep(i - 1)}}},[(i - 1 >= _vm.step)?_c('span',[_vm._v(_vm._s(i))]):_c('q-icon',{attrs:{"name":"fas fa-check"}})],1)}),1):_vm._e()],2)]),_c('q-form',{ref:"formRef",class:_vm.formDesign.formClass},_vm._l((_vm.visibleFields),function(field){return _c('div',{key:("field-" + (field.key)),class:field.contentClass || _vm.formDesign.fieldContentClass},[_c('q-slide-transition',{attrs:{"appear":_vm.formDesign.transition,"duration":_vm.formDesign.transitionDuration}},[_c('base-simple-field',{attrs:{"value":_vm.value[field.key],"config":field,"form-value":_vm.value,"label":_vm.te(("fields." + (field.label || field.key) + ".label")) &&
            field.label !== ''
              ? _vm.t(("fields." + (field.label || field.key) + ".label"))
              : '',"placeholder":_vm.te(("fields." + (field.label || field.key) + ".placeholder"))
              ? _vm.t(("fields." + (field.label || field.key) + ".placeholder"))
              : '',"tooltip":_vm.te(("fields." + (field.label || field.key) + ".tooltip")) &&
            field.label !== ''
              ? _vm.t(("fields." + (field.label || field.key) + ".tooltip"))
              : '',"disable-text":_vm.te(("fields." + (field.label || field.key) + ".disable_text"))
              ? _vm.t(("fields." + (field.label || field.key) + ".disable_text"))
              : '',"hint":_vm.te(("fields." + (field.label || field.key) + ".hint"))
              ? _vm.t(("fields." + (field.label || field.key) + ".hint"))
              : undefined,"tooltip-info":_vm.te(("fields." + (field.label || field.key) + ".tooltip"))
              ? _vm.t('tooltip_info')
              : '',"show-tooltip-by-default":_vm.te(("fields." + (field.label || field.key) + ".showTooltipByDefault")),"tooltip-class":_vm.formDesign.tooltipClass,"label-class":_vm.formDesign.labelClass,"field-class":_vm.formDesign.fieldClass,"loading":_vm.loading || _vm.forceLoading,"last-update-key":_vm.lastUpdateKey,"tooltip-label-class":_vm.formDesign.tooltipLabelClass},on:{"input":function($event){return _vm.onChangeValue(field.key, $event, field)}},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)})],1)],1)}),0),_vm._t("form-actions",[_c('div',{class:_vm.formDesign.actionsClass},[_vm._t("action-button",[(_vm.step > 0)?_c('a-secondary-btn',{attrs:{"label":_vm.$t('go_back').toString()},on:{"click":function($event){return _vm.onBack()}}}):_vm._e()]),_vm._t("next-button",[_c('a-primary-btn',{staticClass:"q-ml-auto",attrs:{"disable":_vm.isDisable,"label":_vm.te('next') ? _vm.t('next') : _vm.$t('next').toString(),"active":!_vm.isDisable,"loading":false},on:{"click":function($event){return _vm.onNext()}}})])],2)],{"actions":{ next: _vm.onNext, back: _vm.onBack }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }