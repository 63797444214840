import { ref, onMounted, onUnmounted, Ref } from '@vue/composition-api';

import { useRouter, useStore } from '../composables';
import { ChatBase } from '@ligo/shared/utils';

// time in millisecond to wait before showing the inactivity_modal modal
const MODAL_APPEARENCE_TIME_MINUTES = 15;
const MODAL_APPEARENCE_TIME = MODAL_APPEARENCE_TIME_MINUTES * 60 * 1000;

// time in millisecond to wait before automaticaly logging out the user
const LOGOUT_TIME = MODAL_APPEARENCE_TIME + 5 * 60 * 1000;

export function useInactivityModal() {
  const showModal = ref(false);
  const warningTimer: Ref<NodeJS.Timeout | undefined> = ref(undefined);
  const logoutTimer: Ref<NodeJS.Timeout | undefined> = ref(undefined);

  function setTimers() {
    showModal.value = false;
    if (router.currentRoute.meta?.noUserInfoNeeded) return;
    warningTimer.value = setTimeout(() => {
      showModal.value = true;
    }, MODAL_APPEARENCE_TIME);

    logoutTimer.value = setTimeout(() => {
      onLogout();
    }, LOGOUT_TIME);
  }

  function resetTimers() {
    if (!showModal.value) {
      clearTimeout(warningTimer.value);
      clearTimeout(logoutTimer.value);
      setTimers();
    }
  }

  onMounted(() => {
    window.addEventListener('click', resetTimers);
    window.addEventListener('mousemove', resetTimers);
    window.addEventListener('mousedown', resetTimers);
    window.addEventListener('scroll', resetTimers);
    window.addEventListener('keypress', resetTimers);
    window.addEventListener('load', resetTimers);
    setTimers();
  });

  onUnmounted(() => {
    window.removeEventListener('click', resetTimers);
    window.removeEventListener('mousemove', resetTimers);
    window.removeEventListener('mousedown', resetTimers);
    window.removeEventListener('scroll', resetTimers);
    window.removeEventListener('keypress', resetTimers);
    window.removeEventListener('load', resetTimers);
    resetTimers();
  });

  const store = useStore();
  const router = useRouter();

  router.afterEach(() => {
    showModal.value = false;
    resetTimers();
  });

  function onLogout() {
    showModal.value = false;
    ChatBase.closeWidget();
    if (router.currentRoute.meta?.noUserInfoNeeded) return;
    store.dispatch('authentication/logout').then(() => {
      store.dispatch('company/cleanState');
      router.push({
        name: 'Login',
        query: { redirect_url: window.location.pathname }
      });
    });
    resetTimers();
  }

  function onRefresh() {
    router.go(0);
  }

  return {
    onLogout,
    onRefresh,
    showModal,
    modalAppearanceTime: MODAL_APPEARENCE_TIME_MINUTES
  };
}
