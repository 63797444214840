
















import { ChatBase } from '@ligo/shared/utils';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NeedHelp',
  setup() {
    function openChat() {
      ChatBase.openWidget();
    }
    return { openChat };
  }
});
