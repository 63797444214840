













































import { computed, defineComponent } from '@vue/composition-api';
import ASecondaryBtn from '../app/buttons/ASecondaryBtn.vue';
import { RawLocation } from 'vue-router';

export default defineComponent({
  name: 'CtaBanner',
  components: { ASecondaryBtn },
  props: {
    title: {
      type: String
    },
    icon: {
      type: String,
      required: true
    },
    cta: {
      type: String
    },
    to: {
      type: Object as () => RawLocation
    },
    bg: {
      type: String,
      default: 'bg-np-secondary-500'
    },
    textColor: {
      type: String,
      default: 'text-dark-blue'
    },
    iconSize: {
      type: String,
      default: '18px'
    },
    showClose: {
      type: Boolean
    }
  },
  setup(props) {
    const setIconColor = computed(() =>
      props.textColor ? props.textColor : 'subdued'
    );

    return {
      setIconColor
    };
  }
});
