

















































































































































































































































































































































import {
  Notary,
  NotaryProductDocumentKind,
  NotaryProductTypeSlug
} from '@ligo/dashboard/store';
import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { useFinalizedProduct } from './finalized-product.hook';
import {
  onDownload,
  getLastUpdatedDocument,
  getDocumentsBy
} from '../documents.hooks';
import DocumentsFolder from '../DocumentsFolder.vue';
import DocumentNameClickable from '../../global/DocumentNameClickable.vue';
import { APrimaryBtn, ABtn } from '../../base/app/buttons';
import { useI18n, useStore } from '../../../composables';
import { useDynamicText } from '../hooks/dynamicText.hooks';
import { LottieAnimation } from '@ligo/shared/components';
import { CtaBanner } from '../../base';

const DOCUMENT_TYPES = [
  NotaryProductDocumentKind.USER_UPLOADED,
  NotaryProductDocumentKind.NOTARY_UPLOADED
];

const CONFETTI_LOTTIE =
  'https://lottie.host/8a1cdb82-5f67-4973-ac20-047493495092/lVozK3l2db.json';

export default defineComponent({
  name: 'FinalizedProduct',
  components: {
    CtaBanner,
    DocumentNameClickable,
    DocumentsFolder,
    LottieAnimation,
    APrimaryBtn,
    ABtn
  },
  props: {
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    image: {
      type: String,
      default: '/dashboard/icons/finalized/fond.svg'
    },
    notary: {
      type: Object as PropType<Notary>
    },
    productData: {
      type: Object
    }
  },
  setup(props, { emit }) {
    emit('stop-loading');
    const store = useStore();
    const notaryProductId = store.getters['notaryProduct/getNotaryProductId'];

    const {
      deeds,
      kvks,
      shareholder_registers,
      loading,
      items
    } = useFinalizedProduct();
    const showAnim = ref(true);
    const i18n = useI18n();
    const { nt } = useDynamicText(i18n, props.notary);

    const {
      items: uploadedDocuments,
      load: uploadedDocumentsLoad
    } = getDocumentsBy(notaryProductId, {
      kinds: DOCUMENT_TYPES
    });

    const lastUpdatedUploadedDocuments = computed(() => {
      if (!uploadedDocuments.value || uploadedDocuments.value.length === 0) {
        return '';
      }
      return getLastUpdatedDocument(uploadedDocuments.value);
    });

    const {
      items: othersUploadedDocuments,
      load: othersUploadedDocumentsLoad
    } = getDocumentsBy(notaryProductId, {
      kinds: DOCUMENT_TYPES
    });

    const lastUpdatedOtherUploadedDocuments = computed(() => {
      if (!uploadedDocuments.value || uploadedDocuments.value.length === 0) {
        return '';
      }
      return getLastUpdatedDocument(uploadedDocuments.value);
    });

    const totalFinalizedDocuments = computed(() => {
      const deeds_length = deeds.value?.length || 0;
      const kvks_length = kvks.value?.length || 0;
      const shareholder_registers_length =
        shareholder_registers.value?.length || 0;
      const additional_documents =
        kvks_length == 0 && props.productType != NotaryProductTypeSlug.TRANSFER
          ? 1
          : 0;

      return (
        deeds_length +
        kvks_length +
        shareholder_registers_length +
        additional_documents
      );
    });

    const STEPS = [
      {
        name: 'incorporate',
        done: true
      },
      {
        name: 'open_bank',
        done: false
      },
      {
        name: 'obligations',
        done: false
      }
    ];

    function onCompleteAnimation() {
      showAnim.value = false;
    }

    return {
      deeds,
      kvks,
      shareholder_registers,
      totalFinalizedDocuments,
      loading,
      onDownload,
      items,
      NotaryProductTypeSlug,
      notaryProductId,
      STEPS,
      nt,
      showAnim,
      CONFETTI_LOTTIE,
      DOCUMENT_TYPES,
      uploadedDocuments,
      uploadedDocumentsLoad,
      lastUpdatedUploadedDocuments,
      othersUploadedDocuments,
      othersUploadedDocumentsLoad,
      lastUpdatedOtherUploadedDocuments,
      onCompleteAnimation
    };
  }
});
