



























import {
  defineComponent,
  watch,
  ref,
  computed,
  PropType
} from '@vue/composition-api';
import CustomerDrawer from './CustomerDrawer.vue';
import CustomerToolbar from './CustomerToolbar.vue';
import UpgradeNotification from '../components/global/UpgradeNotification.vue';
import { ChatBase, useStoreValue } from '@ligo/shared/utils';
import { useRouter, useStore } from '../composables';
import { Screen } from 'quasar';
import { GlobalPaymentModal } from '../components/payment';
import { PaymentQuery } from '@ligo/shared/models';

const signatureFlow = [
  'DocumentPreparation',
  'ActiveContracts',
  'SigningDocument'
];

export default defineComponent({
  components: {
    CustomerDrawer,
    CustomerToolbar,
    UpgradeNotification,
    GlobalPaymentModal
  },
  props: {
    paymentUuid: {
      type: String
    },
    paymentStep: {
      type: String
    },
    adyenData: {
      type: Object as PropType<PaymentQuery>
    }
  },
  setup(props) {
    const router = ref(useRouter());
    const store = useStore();
    const paymentModal = useStoreValue(store)('layout/getPaymentModal');
    const storedPaymentUuid = useStoreValue<string>(store)(
      'layout/getPaymentUuid'
    );

    const currentUuid = ref(props.paymentUuid || storedPaymentUuid.value);

    if (currentUuid.value) {
      paymentModal.value = true;
    }

    const mini = computed(() => Screen.width <= 750);
    const drawerState = ref(false);

    function changeState(state: boolean) {
      drawerState.value = state;
    }

    function closePaymentModal() {
      paymentModal.value = false;
      currentUuid.value = null;
      if (props.paymentUuid) router.value.replace({ query: null });
      store.dispatch('layout/closePaymentModal');
    }

    watch(
      () => props.paymentUuid || storedPaymentUuid.value,
      (uuid) => {
        if (uuid) {
          currentUuid.value = uuid;
          paymentModal.value = true;
        }
      }
    );

    watch(
      () => router.value.currentRoute.name,
      (name) => {
        if (signatureFlow.includes(name)) {
          ChatBase.closeWidget();
        }
      },
      { immediate: true }
    );
    return {
      mini,
      drawerState,
      paymentModal,
      storedPaymentUuid,
      currentUuid,
      closePaymentModal,
      changeState
    };
  }
});
