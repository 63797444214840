




































































































import { defineComponent, computed, ref } from '@vue/composition-api';
import { useNotifications } from '../customer-layout.hooks';

export default defineComponent({
  name: 'NotificationList',
  props: {
    mini: Boolean
  },
  setup() {
    const notificationCountMsg = computed(() =>
      unseenCount.value == 0 ? '' : unseenCount.value
    );

    const showSmallBadge = computed(() => {
      return notificationCountMsg.value.toString().length > 2;
    });

    const {
      items,
      unseenCount,
      loadingNotifications,
      lastNotifications,
      onMarkAsSeen,
      onSeeNotification,
      onScroll
    } = useNotifications();

    const showMenu = ref(false);
    return {
      items,
      unseenCount,
      loadingNotifications,
      lastNotifications,
      notificationCountMsg,
      showSmallBadge,
      showMenu,
      onScroll,
      onSeeNotification,
      onMarkAsSeen
    };
  }
});
