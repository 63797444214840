













































import {
  NotaryProductDocumentKind,
  NotaryProductTypeSlug,
  NotaryProductDocument
} from '@ligo/dashboard/store';
import { defineComponent, PropType, ref } from '@vue/composition-api';
import { onDownload } from '../documents.hooks';
import DocumentNameClickable from '../../global/DocumentNameClickable.vue';
import { CtaBanner } from '../../base';

export default defineComponent({
  name: 'ReadAndPrint',
  components: { CtaBanner, DocumentNameClickable },
  props: {
    notaryProductId: {
      type: String,
      required: true
    },
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<Array<NotaryProductDocument>>,
      default: () => []
    }
  },
  setup() {
    const selectedDocument = ref();

    return {
      selectedDocument,
      onDownload,
      NotaryProductDocumentKind
    };
  }
});
