import { computed, watch } from '@vue/composition-api';
import { ApiService, ChatBase } from '@ligo/shared/utils';
import { COMPANY_KEY, RESOURCES } from '@ligo/dashboard/customer/store';
import moment from 'moment';
import { useStore, useI18n } from './composables';
import Vue from 'vue';
import { Member } from 'libs/dashboard/customer/store/src/lib/models/member';

export function loadCompanyFromStorage() {
  const companyId = localStorage.getItem(COMPANY_KEY);
  if (companyId) ApiService.setCustomHeader(COMPANY_KEY, companyId);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInit() {
  const store = useStore();
  const i18n = useI18n();

  function setCompanyHeader() {
    // The company selected by the user is store in Local Storage and passed in all api requests
    const companyId = store.getters['company/getCompanyId'];
    if (companyId) ApiService.setCustomHeader(COMPANY_KEY, companyId);
  }

  function loadChatBase() {
    ChatBase.initConversationService();
    if (store.getters['authentication/accessToken']) {
      ApiService.get<Member>(RESOURCES.ME)
        .then((response) => {
          const user: Member = response.data;
          ChatBase.identifyUser(user.uuid, user.user_hash, {
            name: `${user.firstname} ${user.lastname}`,
            email: user.email,
            phonenumber: user.phone
          });
        })
        .catch((e) => {
          console.error(
            `There was an error identifying the user in Chatbase: ${e}`
          );
        });
    }
  }

  function setLanguage() {
    // Use the the locale property of the user to set the language on first load
    const lang = computed(() => store.getters['authentication/language']);
    watch(lang, (newLang) => {
      const localeMap = {
        en: 'en-us',
        nl: 'nl'
      };
      if (newLang != undefined) {
        i18n.locale = localeMap[newLang];
        moment.locale(newLang);
        localStorage.setItem('locale', localeMap[newLang]);
      }
    });
  }

  function loadUserInfo() {
    if (!Vue['Router'].currentRoute.meta?.noUserInfoNeeded) {
      store.dispatch('authentication/loadUserInfo');
    }
  }

  function init() {
    setCompanyHeader();
    setLanguage();
    loadChatBase();
    loadUserInfo();
  }
  return { init };
}
