








import { defineComponent } from '@vue/composition-api';
import { TokenInterface } from '@ligo/shared/utils';
import {
  ContractService,
  ExtraServiceService,
  MembershipService
} from '@ligo/dashboard/customer/store';

export default defineComponent({
  name: 'TokenAuth',
  props: {
    access_token: {
      type: String
    },
    client: {
      type: String
    },
    expiry: {
      type: String
    },
    uid: {
      type: String
    },
    redirect_url: {
      type: String,
      default: '/dashboard'
    },
    company: {
      type: String
    },
    contractUuid: {
      type: String
    }
  },
  setup(props, { root }) {
    if (!props.company) {
      root.$store.dispatch('company/cleanState');
    } else {
      root.$store.dispatch('company/select', props.company);
    }

    if (props.contractUuid) {
      ContractService.saveSelection(props.contractUuid);
    }

    if (!props.access_token) {
      const route = { name: 'Login' };
      if (props.redirect_url) {
        route['query'] = { redirect_url: props.redirect_url };
      }
      root.$router.push(route);
    } else {
      const token: TokenInterface = {
        access_token: props.access_token,
        client: props.client,
        expiry: props.expiry,
        uid: props.uid,
        firstname: '',
        lastname: ''
      };

      root.$store
        .dispatch('authentication/auth_redirection', token)
        .then(async () => {
          const redirected =
            (await ContractService.checkAndClaimFromStorage()) ||
            (await MembershipService.checkAndCreateFromStorage());
          await ExtraServiceService.checkAndCreateFromStorage();
          if (!redirected) {
            const redirect_to = props.redirect_url
              ? props.redirect_url
              : '/dashboard';
            root.$router.push(redirect_to);
          }
        });
    }
  }
});
