











import {
  defineComponent,
  onBeforeMount,
  onMounted
} from '@vue/composition-api';
import { ApiService, TokenService } from '@ligo/shared/utils';
import { Router } from './router';
import { useInit } from '@ligo/dashboard/customer/components';
import { useEnvironment } from '../../../netlify_functions';
import { initAnalytics } from '@ligo/shared/analytics';
import InactivityModal from 'libs/dashboard/customer/components/src/lib/layouts/InactivityModal.vue';
import { useInactivityModal } from 'libs/dashboard/customer/components/src/lib/layouts/inactivity-modal.hook';

export default defineComponent({
  name: 'App',
  components: {
    InactivityModal
  },
  setup(_, { root }) {
    const {
      onLogout,
      onRefresh,
      showModal,
      modalAppearanceTime
    } = useInactivityModal();

    onBeforeMount(() => {
      ApiService.mount401Interceptor((error) => {
        if (error.request.status == 401) {
          TokenService.removeToken();
          ApiService.removeHeader();
          if (Router.currentRoute.name != 'Login') {
            Router.push({
              name: 'Login',
              query: { redirect_url: window.location.pathname }
            });
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      });
    });
    onMounted(() => {
      initAnalytics(useEnvironment().SEGMENT_KEY);
      const { init } = useInit();
      init();
    });

    return {
      onLogout,
      onRefresh,
      showModal,
      modalAppearanceTime
    };
  }
});
