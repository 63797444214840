












































































import { computed, defineComponent, watch } from '@vue/composition-api';
import { useStore, useRouter } from '../composables';
import { HomePageInformation } from '../components/utils/useCustomerPageInformation.hooks';
import HomeActionCard from '../components/home/HomeActionCard.vue';
import FreeContractBanner from '../components/home/FreeContractBanner.vue';
import { EXTERNAL_RESOURCES } from '@ligo/dashboard/customer/store';
import { useAddDocument } from '../components/documents/panel/add-document.hook';
import BasePage from '../components/base/BasePage.vue';
import { useHomeConfig, BannerInformation } from '../components/home/home.hook';

const ServiceIcon = 'fal fa-building';
const ESignIcon = 'fal fa-file-signature';
const DocumentIcon = 'fal fa-file-signature';

export default defineComponent({
  name: 'HomePage',
  components: {
    HomeActionCard,
    FreeContractBanner,
    BasePage
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const {
      fileForUpload,
      uploading,
      onFileUpload,
      onSubmitFile
    } = useAddDocument();

    const { t, tc, load, isReady } = HomePageInformation;
    load();

    const freeBannerInformation = computed<BannerInformation>(() => {
      if (!isReady()) return {} as BannerInformation;
      return {
        hello: t('free_banner.hello'),
        title: t('free_banner.title'),
        items: (t('free_banner.items') as string[] | undefined)?.map(
          (item, i) => {
            if (i < 2) return (count: number) => tc(item, count);
            return t(item);
          }
        ),
        footer: t('free_banner.footer'),
        footerLink: t('free_banner.footer_link'),
        okCta: t('free_banner.ok')
      };
    });

    function goToQuestionnaire() {
      window.location.assign(EXTERNAL_RESOURCES.QUESTIONNAIRE);
    }

    function goToeSignature() {
      router.push({ name: 'SignatureHome' });
    }

    function goToLibrary() {
      router.push({ name: 'DocumentsLibrary' });
    }

    const widgets = [
      {
        name: 'service',
        image: ServiceIcon,
        onClick: goToQuestionnaire
      },
      {
        name: 'esign',
        image: ESignIcon,
        onClick: goToeSignature
      },
      {
        name: 'document',
        image: DocumentIcon,
        onClick: goToLibrary
      }
    ];

    const user = computed(() => store.getters['authentication/user']);

    const ready = computed(() => !!user.value && isReady());

    const { pendingTasks, runningIncorporations } = useHomeConfig();

    const rowWidgets = computed(() => {
      return [
        {
          icon: 'tasks.icon',
          title: 'tasks.title',
          badge: 'tasks.badge',
          nextIcon: 'tasks.next_icon',
          seeAll: 'tasks.see_all',
          count: pendingTasks.value,
          to: 'Reminders'
        },
        {
          icon: 'incorporations.icon',
          title: 'incorporations.title',
          badge: 'incorporations.badge',
          nextIcon: 'incorporations.next_icon',
          seeAll: 'incorporations.see_all',
          count: runningIncorporations.value,
          to: 'Incorporations'
        }
      ];
    });

    watch(
      () => fileForUpload.value,
      (file) => {
        if (file) {
          onSubmitFile(false);
        }
      }
    );

    return {
      user,
      ready,
      widgets,
      fileForUpload,
      uploading,
      rowWidgets,
      freeBannerInformation,
      t,
      tc,
      onSubmitFile,
      goToQuestionnaire,
      onFileUpload
    };
  }
});
