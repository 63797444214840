var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-banner',{staticClass:"full-width row relative-position items-center q-px-md q-py-md",class:_vm.bg},[_c('div',{staticClass:"full-width row items-center font-12 w-400",class:_vm.textColor},[_c('q-icon',{attrs:{"name":_vm.icon,"size":_vm.iconSize,"color":_vm.setIconColor}}),_c('div',{class:{
        'col row justify-between items-center': true,
        'q-pl-md q-pr-lg': !_vm.$q.screen.lt.md,
        'col-12 q-mt-sm': _vm.$q.screen.lt.md
      }},[_c('div',{class:{
          col: true,
          'q-pr-md': !_vm.$q.screen.lt.md,
          'col-12 q-mb-sm': _vm.$q.screen.lt.md
        }},[(_vm.title)?[_vm._v(" "+_vm._s(_vm.title)+" ")]:[_vm._t("default")]],2),(_vm.cta)?_c('a-secondary-btn',{attrs:{"label":_vm.cta,"to":_vm.to}}):_vm._e()],1)],1),(_vm.showClose)?_c('q-btn',{staticClass:"q-pa-xs close-btn-position",attrs:{"size":"8px","round":"","dense":"","flat":"","icon":"fal fa-xmark"},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }