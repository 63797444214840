



























































import { defineComponent, PropType } from '@vue/composition-api';
import { ASecondaryBtn } from '../../components/base/app/buttons';
export default defineComponent({
  name: 'LawyerCard',
  components: { ASecondaryBtn },
  props: {
    name: String,
    description: String,
    tags: Array as PropType<string[]>,
    phone: String,
    email: String,
    fee: String,
    image: String,
    firm: String,
    role: String,
    hideCta: {
      type: Boolean,
      default: false
    },
    hideShadow: {
      type: Boolean,
      default: false
    }
  }
});
