




























































































import { PropType, defineComponent, ref } from '@vue/composition-api';
import { menuItemsHandler, notifySuccess } from './menu-list.hook';
import { ASecondaryBtn } from '../../../base/app/buttons';
import MenuFormCard from './MenuFormCard.vue';
import BusinessActivitiesCard from './BusinessActivitiesCard.vue';
import { NotaryProductSlug } from '@ligo/bv-flow/store';
import {
  useAddressFields,
  useCompanyNameField
} from '../../hooks/common-field-definitions.hooks';
import { ExtraChangesPageInformation } from '../../../utils/useCustomerPageInformation.hooks';
import WarningModal from './WarningModal.vue';
import OtherChangesCard from './OtherChangesCard.vue';
import { ChatBase } from '@ligo/shared/utils';
import { NotaryProductEvent } from '@ligo/dashboard/store';

export default defineComponent({
  components: {
    MenuFormCard,
    BusinessActivitiesCard,
    WarningModal,
    OtherChangesCard,
    ASecondaryBtn
  },
  props: {
    notaryProductUuid: {
      type: String,
      required: true
    },
    productType: {
      type: String as PropType<NotaryProductSlug>
    },
    productData: {
      type: Object
    },
    stage: {
      type: String
    }
  },
  setup(props, { emit }) {
    const selectedOption = ref('');
    const loading = ref(true);

    const { load: loadPageInformation, t } = ExtraChangesPageInformation;

    loadPageInformation().then(() => {
      loading.value = false;
    });

    const companyNameField = [useCompanyNameField(props.productType)];
    const addressFields = useAddressFields();
    const menuItems = menuItemsHandler(props.productType);

    function openChatBaseWidget() {
      ChatBase.openWidget();
    }

    function notifyRegeneration() {
      notifySuccess(t('regenerating'), true);
    }

    function revertProduct() {
      emit('event', NotaryProductEvent.REVERT);
      notifySuccess(t('revert.success'), true);
    }

    return {
      loading,
      menuItems,
      selectedOption,
      companyNameField,
      addressFields,
      t,
      openChatBaseWidget,
      revertProduct,
      notifyRegeneration
    };
  }
});
