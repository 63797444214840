


























































import { Notary, NotaryProductTypeSlug } from '@ligo/dashboard/store';
import { defineComponent, PropType } from '@vue/composition-api';
import { LabelValue } from '@ligo/dashboard/components';
import { CtaBanner } from '../../base';

const ligoPhoneNumber = ' +31 020 303 1043';

export default defineComponent({
  name: 'ContactInfoCard',
  components: { CtaBanner, LabelValue },
  props: {
    productType: {
      type: String as PropType<NotaryProductTypeSlug>
    },
    notary: {
      type: Object as PropType<Notary>
    },
    loading: {
      type: Boolean,
      default: false
    },
    notaryProductId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    function onClickPhone() {
      window.open(`tel:${ligoPhoneNumber}`, '_self');
    }
    return {
      ligoPhoneNumber,
      onClickPhone
    };
  }
});
