import { RouteConfig } from 'vue-router';
import {
  Account,
  Settings,
  CustomerLayout,
  ServiceTypes,
  ServiceTypeDetails,
  Login,
  NotaryProducts,
  ResetPassword,
  NotaryProductDetails,
  ActiveContracts,
  NotaryMap,
  Billing,
  Checkout,
  ContractPreview,
  EmailConfirmation,
  WordEditor,
  CleanWordEditor,
  Register,
  DocumentPreparation,
  SigningDocument,
  PaymentRedirect,
  Home,
  AuthBrandLayout,
  ErrorCompleted,
  ErrorExpired,
  ErrorSentForSigning,
  ErrorDeclined,
  ContractReminders,
  OnboardingPage,
  PackagesPage,
  DocumentsLibrary,
  DgaServices,
  TaxServices,
  SignatureHome,
  BankingLanding,
  BankingAccountCreatePage,
  LawyersList,
  LawyerDetails,
  Error404,
  ChangePassword,
  TokenAuth
} from '@ligo/dashboard/customer/components';

const routes: RouteConfig[] = [
  {
    path: '/dashboard/signature/completed',
    name: 'ErrorCompleted',
    component: ErrorCompleted
  },
  {
    path: '/dashboard/signature/error_expired',
    name: 'ErrorExpired',
    component: ErrorExpired
  },
  {
    path: '/dashboard/signature/error_declined',
    name: 'ErrorDeclined',
    component: ErrorDeclined
  },
  {
    path: '/dashboard/signature/error_sent_for_signing',
    name: 'ErrorSentForSigning',
    component: ErrorSentForSigning
  },
  {
    path: '/dashboard',
    component: CustomerLayout,
    props: (route) => ({
      paymentUuid: route.query.paymentUuid,
      paymentStep: route.query.paymentStep,
      adyenData: {
        sessionId: route.query.sessionId,
        redirectResult: route.query.redirectResult
      }
    }),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      }
    ]
  },
  {
    path: '/dashboard/',
    component: AuthBrandLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
        props: (route) => ({
          redirectUrl: route.query.redirect_url,
          contractUuid: route.query.contract_uuid,
          extraServiceTypeSlug: route.query.extraServiceTypeSlug,
          planId: route.query.plan_id
        }),
        meta: {
          noUserInfoNeeded: true
        }
      },
      {
        path: 'register',
        name: 'Register',
        component: Register,
        props: (route) => ({
          redirectUrl: route.query.redirect_url,
          contractUuid: route.query.contract_uuid,
          extraServiceTypeSlug: route.query.extraServiceTypeSlug,
          planId: route.query.plan_id
        }),
        meta: {
          noUserInfoNeeded: true
        }
      },
      {
        path: 'onboarding',
        name: 'Onboarding',
        component: OnboardingPage,
        props: (route) => ({
          redirectUrl: route.query.redirect_url,
          contractUuid: route.query.contract_uuid,
          extraServiceTypeUuid: route.query.extraServiceTypeUuid,
          planId: route.query.plan_id
        })
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          noUserInfoNeeded: true
        }
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        props: (route) => ({
          access_token: route.query['access-token'],
          client: route.query.client,
          expiry: route.query.expiry,
          uid: route.query.uid
        }),
        meta: {
          noUserInfoNeeded: true,
          requiresAuth: false
        }
      },
      {
        path: 'confirmation',
        name: 'Confirmation',
        component: EmailConfirmation,
        meta: {
          noUserInfoNeeded: true
        }
      }
    ]
  },
  {
    path: '/dashboard/token_auth',
    name: 'TokenAuth',
    component: TokenAuth,
    props: (route) => ({
      access_token: route.query.access_token,
      client: route.query.client,
      expiry: route.query.expiry,
      uid: route.query.uid,
      redirect_url: route.query.redirect_url,
      company: route.query.company_uuid
    })
  },
  {
    path: '/dashboard/notary-location',
    name: 'NotaryMap',
    component: NotaryMap,
    props: (route) => ({
      notaryProductId: route.query.uuid
    })
  },
  {
    path: '/dashboard/editor/:uuid',
    name: 'WordEditor',
    component: WordEditor,
    props: (route) => ({
      contractUuid: route.params.uuid
    })
  },
  {
    path: '/dashboard/new-document',
    name: 'CleanWordEditor',
    component: CleanWordEditor
  },
  {
    path: '/dashboard/contract/:uuid',
    name: 'ContractPreview',
    component: ContractPreview,
    props: (route) => ({
      uuid: route.params.uuid,
      dialog: route.query.dialog,
      paymentUuid: route.query.paymentUuid,
      paymentStep: route.query.paymentStep,
      adyenData: {
        sessionId: route.query.sessionId,
        redirectResult: route.query.redirectResult
      }
    })
  },
  {
    path: '/dashboard/contract/document-preparation/:uuid',
    name: 'DocumentPreparation',
    component: DocumentPreparation,
    props: (route) => ({
      uuid: route.params.uuid,
      assistant: route.query.assistant === 'true'
    })
  },
  {
    path:
      '/dashboard/contract/:contractUuid/signers/:signerUuid/signing-sessions/:sessionUuid',
    name: 'SigningDocument',
    component: SigningDocument,
    meta: {
      noUserInfoNeeded: true
    },
    props: true
  },
  {
    path: '/dashboard/payment/:uuid',
    name: 'AdyenPayment',
    component: PaymentRedirect,
    props: (route) => ({
      uuid: route.params.uuid,
      sessionId: route.query.sessionId,
      redirectResult: route.query.redirectResult
    })
  },
  {
    path: '/dashboard',
    component: CustomerLayout,
    props: (route) => ({
      paymentUuid: route.query.paymentUuid,
      paymentStep: route.query.paymentStep,
      adyenData: {
        sessionId: route.query.sessionId,
        redirectResult: route.query.redirectResult
      }
    }),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'notary-product/:uuid',
        name: 'NotaryProductDetail',
        component: NotaryProductDetails,
        props: (route) => ({
          uuid: route.params.uuid,
          dialog: route.query.dialog,
          pUuid: route.query.pUuid
        })
      },
      {
        path: 'account',
        name: 'Account',
        component: Account,
        meta: {
          hideUpgradeNotification: true
        }
      },
      {
        path: 'legal-plans',
        name: 'LegalPlans',
        meta: {
          hideUpgradeNotification: true
        },
        component: PackagesPage
      },
      {
        path: 'settings',
        name: 'Settings',
        meta: {
          hideUpgradeNotification: true
        },
        component: Settings
      },
      {
        path: 'service-types',
        name: 'ServiceTypes',
        component: ServiceTypes
      },
      {
        path: 'dga-services',
        name: 'DgaServices',
        component: DgaServices
      },
      {
        path: 'tax-services',
        name: 'TaxServices',
        component: TaxServices
      },
      {
        path: 'esignatures',
        name: 'SignatureHome',
        component: SignatureHome
      },
      {
        path: 'service-type/:slug',
        name: 'ServiceTypeDetails',
        component: ServiceTypeDetails,
        props: (route) => ({
          slug: route.params.slug
        })
      },
      {
        path: 'dga-service-type/:slug',
        name: 'DgaServiceTypeDetails',
        component: ServiceTypeDetails,
        props: (route) => ({
          slug: route.params.slug,
          kind: 'dga'
        })
      },
      {
        path: 'tax-service-type/:slug',
        name: 'TaxServiceTypeDetails',
        component: ServiceTypeDetails,
        props: (route) => ({
          slug: route.params.slug,
          kind: 'tax'
        })
      },
      {
        path: 'incorporations',
        name: 'Incorporations',
        component: NotaryProducts,
        props: {
          kind: 'incorporation'
        }
      },
      {
        path: 'modifications',
        name: 'Modifications',
        component: NotaryProducts,
        props: {
          kind: 'modification'
        }
      },
      {
        path: 'tasks',
        name: 'Reminders',
        component: ContractReminders
      },
      {
        // TODO: Remove this route once the api is updated
        path: 'reminders',
        name: 'RemindersOld',
        component: ContractReminders
      },
      {
        path: 'contract',
        name: 'ActiveContracts',
        component: ActiveContracts
      },
      {
        path: 'documents-library',
        name: 'DocumentsLibrary',
        component: DocumentsLibrary,
        props: (route) => ({
          documentSlug: route.query['document-slug']
        })
      },
      {
        path: 'billing/:paymentId',
        name: 'Billing',
        component: Billing,
        props: true
      },
      {
        path: 'checkout/:paymentId',
        name: 'Checkout',
        component: Checkout,
        props: true
      },
      {
        path: 'banking',
        name: 'Banking',
        component: BankingLanding,
        props: (route) => ({
          successAccountCreated: route.query.success === 'true'
        })
      },
      {
        path: 'banking-accounts-create',
        name: 'BankingAccountsCreate',
        component: BankingAccountCreatePage
      },
      {
        path: 'lawyers',
        name: 'Lawyers',
        component: LawyersList
      },
      {
        path: 'lawyers/:slug',
        name: 'LawyerDetails',
        component: LawyerDetails,
        props: true
      }
    ]
  },

  {
    path: '/:catchAll(.*)*',
    name: 'Error404',
    component: Error404
  }
];

export default routes;
